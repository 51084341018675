@charset "utf-8";

/*--------------------------------------------------------
	トップページ
----------------------------------------------------------*/

/* トップページ　SERVICE */
.top-service {
  @include base-wrap--space();
  margin-bottom: 70px;
  &__main {
    @include clearfix();
    margin-bottom: 30px;
    &__image {
      float: left;
      width: 50%;
      height: 220px;
      margin-right: 30px;
      overflow: hidden;
      @include mq(sp) {
        float: none;
        width: auto;
        height: auto;
        margin-right: 0;
      }
      img {
        width: 100%;
      }
    }
  }
  ul {
    @include flex();
    margin-bottom: 30px;
    li {
      width: 32%;
      @include mq(sp) {
        width: auto;
        margin-bottom: 30px;
      }
      &:last-child {
        margin-right: 0;
      }
      img {
        margin-bottom: 20px;
      }
    }
  }
  h3 {
    margin: 0 0 20px;
    color: $dark-main;
    font-size: 30px;
    line-height: 1.2;
    @include mq(sp) {
      @include fz_vw(18);
    }
    a {
      color: $dark-main;
      font-weight: bold;
    }
  }
  p {
    color: #666666;
  }
  .btn-more {
    text-align: right;
    @include mq(sp) {
      text-align: center;
    }
  }
}

/* トップページ　FEED */
.top-news {
  ul {
    margin-bottom: 20px;
    li {
      @include clearfix();
      margin-bottom: 12px;
      .date {
        display: block;
        margin-left: 80px;
        margin-bottom: 10px;
        padding: 9px 0;
        padding-left: 25px;
        border-left: 1px solid #CCCCCC;
        font-size: 15px;
      }
    }
  }
  .btn-more {
    text-align: right;
  }
}
.top-blog {
  @include base-wrap--space();
  position: relative;
  margin-bottom: 80px;
  padding: 0 30px 20px;
  border: 4px solid #CCCCCC;
  @include mq(sp) {
    padding: 0 10px 10px;
  }
  ul {
    display: flex;
    @include mq(sp) {
      display: block;
    }
    li {
      width: 33%;
      margin-bottom: 30px;
      margin-right: 1%;
      @include mq(sp) {
        width: auto;
      }
      &:nth-child(4n) {
        margin-right: 0;
      }
      a {
        @include clearfix();
        display: block;
        padding: 4px;
        &:hover {
          background: $light-gray;
        }
      }
      .thumbnail {
        float: left;
        width: 160px;
        margin-right: 10px;
        img {
          width: 100%;
          vertical-align: middle;
        }
      }
      h3 {
        margin-bottom: 10px;
        color: $dark-main;
        line-height: 1.3;
        @include mq(sp) {
          margin-bottom: 5px;
        }
      }
      .date {
        margin-bottom: 10px;
        font-size: 12px;
        @include mq(sp) {
          margin-bottom: 5px;
        }
      }
      p {
        font-size: 15px;
        line-height: 1.3;
      }
    }
  }
  .btn-more {
    text-align: center;
    a {
      padding: 20px 100px;
      border-radius: 40px;
      font-size: 20px;
      letter-spacing: .2em;
      text-align: center;
    }
  }
}

/* トップページ　ABOUT */
.about {
  margin-bottom: 50px;
  padding: 10px 0 50px;
  background: #E7E7E7;
  &__inner {
    @include base-wrap--space();
  }
  ul {
    li {
      position: relative;
      float: left;
      width: 372px;
      margin-right: 40px;
      @include mq(sp) {
        float: none;
        width: auto;
        margin-right: 0;
        margin-bottom: 20px;
      }
      &:last-child {
        margin-right: 0;
      }
      .thumbnail {
        @include mq(sp) {
          text-align: center;
        }
        img {
          border-radius: 200px;
          border: 14px solid #fff;
          @include mq(sp) {
            box-sizing: border-box;
            border: 10px solid #fff;
          }
        }
      }
      h3 {
        position: relative;
        top: -50px;
        right: -70px;
        width: 300px;
        padding: 10px;
        border-radius: 4px;
        background: $deep-main;
        color: #fff;
        text-align: center;
        font-size: 20px;
        /*-webkit-font-smoothing: antialiased;*/
        @include mq(sp) {
          top: -30px;
          right: 0;
          width: auto;
          @include fz_vw(16);
        }
      }
      p {
        color: #666666;
      }
    }
  }
}

.contact-box {
  @include base-wrap--space();
}
