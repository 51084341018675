@charset "utf-8";

/*--------------------------------------------------------
  ヘッダー
----------------------------------------------------------*/

/* ヘッダー */
.header {
  position: fixed;
  top: 0;
  left: 0;
  background: #fff;
  width: 100%;
  padding: 12px 0 10px;
  box-shadow: 0 1px 4px 2px rgba(0,0,0,0.1);
  line-height: 1.5;
  z-index: 100;
  @include mq(sp) {
    position: static;
    box-shadow: none;
    padding: 0;
    z-index: 100;
  }
  &__inner {
    @include base-wrap();
  }
  &__logo {
    display: inline-block;
    @include mq(sp) {
      padding: 10px;
    }
    img {
      width: 400px;
      height: 80px;
      vertical-align: top;
      @include mq(sp) {
        width: 240px;
        height: 53px;
      }
    }
  }
  &__menu {
    display: none;
  }
}

.gnavi {
  float: right;
  padding: 15px 0;
  @include mq(sp) {
    display: none;
    float: none;
    padding: 0;
  }
  &__list {
    &__item {
      float: left;
      height: 40px;
      @include mq(sp) {
        float: none;
        height: auto;
        background: #666666;
      }
      a {
        display: block;
        margin: 0 15px;
        padding: 0 10px;
        color: #9f832e;
        text-decoration: none;
        font-family: 'Sorts Mill Goudy', serif;
        font-size: 10px;
        @include mq(sp) {
          @include fz_vw(12);
          width: auto;
          margin: 0;
          padding: 16px 10px 10px 40px;
          border-bottom: 1px solid #808080;
          background: url(../img/sp/icn_arrow.png) 20px 18px no-repeat;
          background-size: 7px 14px;
          text-align: left;
        }
        &:hover {
          border-bottom: 2px solid $light-main;
        }
        b {
          padding-left: 4px;
          font-size: 14px;
          font-weight: normal;
        }
        span {
          display: block;
          color: #333333;
          font-family: 'ヒラギノ角ゴ Pro W3', 'Hiragino Kaku Gothic Pro', メイリオ, Meiryo, 'ＭＳ Ｐゴシック', 'MS PGothic', sans-serif;
          font-size: 16px;
          @include mq(sp) {
            display: inline;
            margin-right: 10px;
            color: #fff;
          }
        }
      }
      &:last-child {
        a {
          margin-right: 0;
        }
      }
    }
  }
}

#BtnMenu {
  display: none;
  @include mq(sp) {
    display: block;
    width: 24px;
    position: absolute;
    top: 15px;
    right: 10px;
  }
}