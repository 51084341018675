@charset "utf-8";

/*--------------------------------------------------------
  メインイメージ
----------------------------------------------------------*/

.mainimage {
  img {
    width: 100%;
  }
}