@charset "utf-8";


@mixin opacity($opacity) {
	opacity: $opacity;
	filter: alpha(opacity=($opacity * 100));
}

@mixin clearfix() {
	&:before,
	&:after {
		content: "";
		display: table;
	}
	&:after {
		clear: both;
	}
}

@mixin box-sizing($type) {
	-webkit-box-sizing:$type;
	-moz-box-sizing:$type;
	box-sizing:$type;
}

@mixin border-radius($radius) {
	-webkit-border-radius: $radius;
	-moz-border-radius: $radius;
	-ms-border-radius: $radius;
	border-radius: $radius;
}

@mixin fadeover {
  -webkit-transition: 0.3s ease-in-out;
  -moz-transition: 0.3s ease-in-out;
  -o-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
}

@mixin base-wrap {
	width: 1200px;
  margin-left: auto;
  margin-right: auto;
  @include mq(sp) {
    width: auto;
    margin-left: 0;
    margin-right: 0;
  }
}
@mixin base-wrap--space {
  width: 1200px;
  margin-left: auto;
  margin-right: auto;
  @include mq(sp) {
    width: auto;
    margin-left: 10px;
    margin-right: 10px;
  }
}
@mixin main-wrap {
  width: 920px;
  margin-left: auto;
  margin-right: auto;
  @include mq(sp) {
    width: auto;
    margin-left: 0;
    margin-right: 0;
  }
}
@mixin main-wrap--space {
  width: 920px;
  margin-left: auto;
  margin-right: auto;
  @include mq(sp) {
    width: auto;
    margin-left: 10px;
    margin-right: 10px;
  }
}
@mixin separate {
	position: relative;
	&::before {
		position: absolute;
		bottom: 0;
		left: 50%;
		content: "";
		display: block;
		width: 90px;
		height: 1px;
		margin-left: -95px;
		background: $orange;
	}
	&::after {
		position: absolute;
		bottom: 0;
		left: 50%;
		content: "";
		display: block;
		width: 90px;
		height: 1px;
		margin-left: 5px;
		background: $orange;
	}
}
@mixin arrow {
  position: relative;
  &::before {
    position: absolute;
    bottom: 0;
    left: 50%;
    content: "";
    display: block;
    width: 50px;
    height: 50px;
    margin-left: -25px;
    border-bottom: 1px solid $orange;
    border-right: 1px solid $orange;
    transform: rotate(45deg);
    @include mq(sp) {
      width: 30px;
      height: 30px;
      margin-left: -15px;
    }
  }
}