@charset "utf-8";

/*--------------------------------------------------------
  タイトル
----------------------------------------------------------*/
/* 見出し */
.title {
  margin: 50px 0;
  line-height: 1;
  text-align: center;
  @include mq(sp) {
    margin-bottom: 20px;
    margin-top: 30px;
  }
  .main-title1 {
    display: inline-block;
    margin-bottom: 10px;
    color: $deep-main;
    font-size: 50px;
    font-family: 'Oswald', sans-serif;
    letter-spacing: 2px;
    @include mq(sp) {
      font-size: 25px;
    }
    &::before {
      display: block;
      width: 100px;
      content: '';
      margin: 0 auto;
      padding-top: 30px;
      border-top: 1px solid #999999;
      @include mq(sp) {
        padding-top: 20px;
      }
    }
  }
  .sub-title {
    font-size: 18px;
    color: #999999;
    @include mq(sp) {
      margin-bottom: 30px;
      font-size: 12px;
    }
  }
  .main-title2 {
    display: inline-block;
    margin-bottom: 10px;
    color: $deep-main;
    font-size: 50px;
    font-family: 'Oswald', sans-serif;
    letter-spacing: 2px;
    @include mq(sp) {
      font-size: 25px;
    }
  }
}
#ToolBar a.title {
  margin: 0;
}

/* 下層ページの見出し */
.page {
  h2 {
    position: relative;
    margin-bottom: 30px;
    padding-bottom: 15px;
    border-bottom: 2px solid $light-main;
    font-size: 30px;
    font-weight: bold;
    line-height: 1.3;
    @include mq(sp) {
      @include fz_vw(20);
    }
    &::before {
      position: absolute;
      bottom: -5px;
      left: 0;
      content: "";
      display: block;
      width: 300px;
      height: 4px;
      background: $light-main;
      @include mq(sp) {
        width: 180px;
      }
    }
  }
  h3 {
    margin: 40px 0 30px;
    padding-bottom: 15px;
    border-bottom: 1px dotted #999;
    font-size: 24px;
    @include mq(sp) {
      @include fz_vw(16);
    }
    a {
      text-decoration: none;
      color: #333;
      &:hover {
        text-decoration: underline;
        color: #888;
      }
    }
  }
  h4 {
    margin: 30px 0 20px;
    font-size: 18px;
    font-weight: bold;
  }
  h5 {
    margin-top: 20px;
    font-size: 18px;
  }
}

.notice {
  color: #777;
  font-size: 13px;
}