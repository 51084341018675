@charset "utf-8";

/*--------------------------------------------------------
  フッター
----------------------------------------------------------*/
/* MAP */
.footer {
    &__map {
        &__inner {
            height: 450px;
        }
    }
}

/* コンタクト */
.footer {
    &__contact {
        margin-bottom: 30px;
        background: $dark-main;
        @include mq(sp) {
            margin-bottom: 10px;
        }
        &__inner {
            @include base-wrap--space();
            padding: 40px 0;
            text-align: center;
            font-size: 36px;
            @include mq(sp) {
                font-size: 20px;
            }
        }
        &__title {
            margin-right: 40px;
            color: $light-main;
            font-family: 'Oswald', sans-serif;
            letter-spacing: 3px;
            @include mq(sp) {
                margin-right: 20px;
            }
        }
        &__tel {
            color: #fff;
            font-family: 'Oswald', sans-serif;
            letter-spacing: 3px;
            a {
                color: #fff;
            }
        }
        &__time {
            color: #000;
            font-size: 16px;
        }
    }
}

/* ロゴナビ */
.footer {
    &__inner {
        @include base-wrap--space();
    }
    &__left {
        display: inline-block;
        padding-left: 50px;
        @include mq(sp) {
            display: block;
            padding-left: 0;
            margin-bottom: 20px;
        }
    }
    &__logo {
        @include mq(sp) {
            width: 50%;
            margin: 20px auto 0;
        }
        img {
            width: 180px;
            height: 60px;
            @include mq(sp) {
            }
        }
    }
    &__address {
        font-size: 13px;
        line-height: 1.2;
        @include mq(sp) {
            text-align: center;
        }
        &__name {
            margin-bottom: 8px;
            font-size: 18px;
        }
        &__address {
            margin-bottom: 5px;
        }
        &__phone {
            margin-bottom: 5px;
        }
    }
}

.fnavi {
    float: right;
    padding-top: 18px;
    @include mq(sp) {
        float: none;
    }
    &__list {
        @include mq(sp) {
            display: flex;
            flex-wrap: wrap;
        }
        &__item {
            float: left;
            padding: 15px 10px;
            white-space: nowrap;
            @include mq(sp) {
                @include box-sizing(border-box);
                float: none;
                width: 50%;
                padding: 5px 10px;
                border: 1px solid #fff;
                background: $light-main;
            }
            a {
                padding: 0 10px 8px;
                color: #333;
                font-size: 13px;
                text-decoration: none;
                &:hover {
                    @include mq(pc) {
                        border-bottom: 2px solid $light-main;
                    }
                }
            }
        }
    }
}

.copyright {
    clear: both;
    padding: 50px 0 20px;
    color: #999;
    font-size: 15px;
    text-align: center;
    @include mq(sp) {
        @include fz_vw(12);
        padding-top: 20px;
    }
}


/* ページ上へ戻る */
#TopLink {
    position: fixed;
    bottom: 50px;
    left: 50%;
    z-index: 10000;
    margin-left: 540px;
    text-align: right;
    text-decoration: none;
    font-family: 'Oswald', sans-serif;
    cursor: pointer;
    opacity: 0.7;
    @include mq(sp) {
        display: none;
    }
}
#TopLink img:hover {
    opacity: 0.5;
    @include mq(sp) {
        opacity: 1;
    }
}
