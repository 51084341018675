@charset "utf-8";

/*--------------------------------------------------------
	ボタン
----------------------------------------------------------*/

.button {
    display: inline-block;
    margin: 10px 0;
    padding: 15px 50px;
    border: 3px solid $dark-main;
    border-radius: 40px;
    background: $dark-main;
    color: #fff;
    font-size: 18px;
    text-align: center;
    text-decoration: none;
    line-height: 1;
    @include mq(sp) {
        @include fz_vw(16);
        padding: 10px 40px;
    }
    &:hover {
        background: #fff;
        color: $dark-main;
        text-decoration: none;
    }
}
.btn-small {
    display: inline-block;
    padding: 5px 20px 5px 40px;
    border-radius: 4px;
    background: $dark-main url(../img/common/icn_arrow01.png) 10px center no-repeat;
    color: $light-main;
    font-size: 14px;
    letter-spacing: 2px;
}
.btn-midium {
    width: 230px;
    padding: 20px 40px 20px 60px;
    border-radius: 4px;
    background: $dark-main url(../img/common/icn_arrow01.png) 10px center no-repeat;
    color: $light-main;
    font-size: 20px;
    letter-spacing: 2px;
}
.btn-big {
    display: inline-block;
    width: 380px;
    padding: 30px 10px 30px 20px;
    border-radius: 4px;
    background: #000 url(../img/common/icn_arrow01.png) 10px center no-repeat;
    font-size: 24px;
    letter-spacing: 2px;
}
.btn-wrap {
    text-align: center;
    a {
        display: inline-block;
        margin: 10px 0;
        padding: 20px 80px;
        border: 3px solid $sub;
        border-radius: 4px;
        background: $sub;
        color: #fff;
        font-size: 30px;
        text-align: center;
        text-decoration: none;
        @include mq(sp) {
            @include fz_vw(18);
            display: block;
            padding: 10px;
        }
        &:hover {
            background: #fff;
            color: $sub;
            text-decoration: none;
        }
    }
}