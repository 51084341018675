@charset "utf-8";

/*--------------------------------------------------------
  固定ページ共通
----------------------------------------------------------*/

/* メインビジュアル */
.page-mv {
  &__title {
    position: relative;
    @include mq(sp) {
      margin-bottom: 30px;
    }
    &--noimage {
      @include base-wrap();
      padding-top: 60px;
      font-size: 40px;
      font-weight: normal;
      text-align: center;
      @include mq(sp) {
        @include fz_vw(18);
        padding-top: 60px;
      }
    }
    &__wrapper {
      height: 200px;
      background: url(/theme/bccolumn/img/page/mv_page.jpg) no-repeat;
      background-size: cover;
      @include mq(sp) {
        height: 160px;
        margin-bottom: 30px;
      }
    }
  }
}



/* サブページ共通 */
.page {
  p {
    margin-bottom: 30px;
    @include mq(sp) {
      margin-bottom: 20px;
    }
  }
  .section {
    margin-bottom: 60px;
    @include mq(sp) {
      margin-bottom: 30px;
    }
  }
  .copy {
    color: $dark-main;
    font-size: 30px;
    font-weight: bold;
    @include mq(sp) {
      @include fz_vw(18);
    }
  }
}
@include mq(sp) {
  .sp-ta-center {
    text-align: center;
  }
}

/* サービス（シートクリーニング） */
// シートクリーニングのお問い合わせはこちら
.banner-contact {
  width: 800px;
  margin: 0 auto 50px;
  border: 4px solid $sub;
  @include mq(sp) {
    width: auto;
  }
  p {
    margin-bottom: 20px;
  }
  &__title {
    background: $sub;
    color: #fff;
    font-size: 28px;
    font-weight: bold;
    text-align: center;
    @include mq(sp) {
      @include fz_vw(24);
      padding: 10px 0 20px;
      line-height: 1.2;
    }
    b {
      font-size: 36px;
      @include mq(sp) {
        display: block;
        @include fz_vw(28);
      }
    }
  }
  &__wrap {
    @include flex();
    padding: 10px 50px 30px;
    @include mq(sp) {
      padding: 10px;
    }
  }
  &__tel {
    &__no {
      margin-bottom: 0 !important;
      color: $dark-main;
      font-size: 32px;
      font-weight: bold;
      text-align: center;
      line-height: 1.5;
      @include mq(sp) {
        @include fz_vw(26);
      }
      a {
        color: $dark-main;
      }
    }
    &__time {
      margin-bottom: 0 !important;
      @include mq(sp) {
        @include fz_vw(14);
        margin-bottom: 20px !important;
        text-align: center;
      }
    }
  }
  &__contact {
    text-align: center;
    @include mq(sp) {
      font-size: 20px;
    }
  }
}
// こんな悩みありませんか？
.service-trouble {
  p {
    margin-bottom: 10px;
  }
  &__title {
    margin-bottom: 30px !important;
    font-size: 50px;
    font-weight: bold;
    text-align: center;
    @include mq(sp) {
      @include fz_vw(24);
      margin-bottom: 15px !important;
    }
  }
  &__list {
    @include flex();
    &__item {
      width: 32%;
      @include mq(sp) {
        width: auto;
      }
      &__title {
        margin-bottom: 10px !important;
        padding: 10px 20px;
        background: $main;
        color: #fff;
        font-size: 20px;
        line-height: 1.5;
        @include mq(sp) {
          @include fz_vw(18);
        }
      }
    }
  }
}
// 当社の特徴
.service-feature {
  padding: 30px 100px;
  background: $light-gray;
  @include mq(sp) {
    padding: 10px;
  }
  p {
    margin-bottom: 10px;
  }
  &__title {
    color: $dark-main;
    font-size: 50px;
    font-weight: bold;
    text-align: center;
    @include mq(sp) {
      @include fz_vw(30);
    }
  }
  &__list {
    &__item {
      strong {
        color: $dark-main;
        font-weight: bold;
      }
    }
  }
}
// olタグチェックボックス上書き
.page ol > li.service-feature__list__item {
  position: relative;
  padding-left: 50px;
  font-size: 30px;
  @include mq(sp) {
    @include fz_vw(18);
    padding-left: 36px;
  }
  &::before {
    top: 10px;
    left: 0;
    content: "";
    width: 30px;
    height: 30px;
    border: 2px solid $dark-main;
    border-radius: 4px;
    @include mq(sp) {
      top: 6px;
      width: 20px;
      height: 20px;
    }
  }
  &::after {
    position: absolute;
    top: 2px;
    left: 14px;
    content: "";
    display: block;
    width: 12px;
    height: 28px;
    border-right: 6px solid $dark-main;
    border-bottom: 6px solid $dark-main;
    transform: rotate(50deg);
    @include mq(sp) {
      top: 2px;
      left: 10px;
      width: 8px;
      height: 18px;
      border-width: 4px;
    }
  }
}
// シートクリーニングの様子
.service-photo {
  p {
    margin-bottom: 0;
  }
  &__title {
    margin-bottom: 30px !important;
    font-size: 50px;
    font-weight: bold;
    text-align: center;
    @include mq(sp) {
      @include fz_vw(24);
      margin-bottom: 15px !important;
    }
  }
  &__list {
    @include flex-sp(wrap);
    &__item {
      width: 32%;
      border: 1px solid $light-gray;
      text-align: center;
      @include mq(sp) {
        width: 48%;
        text-align: left;
      }
    }
  }
}
// liタグチェックボックス上書き
.page ul {
  margin-left: 0;
}
.page ul > li.service-photo__list__item {
  margin-bottom: 30px;
  padding: 15px;
  @include mq(sp) {
    padding: 8px;
  }
  &::before {
    display: none;
  }
  p {
    margin-bottom: 0;
  }
}
// 料金表
.service-price {
  @include mq(sp) {
    border-top: 1px solid $main;
  }
  table {
    th {
      @include mq(sp) {
        display: none;
      }
    }
  }
}
.main .service-price table td:first-child {
  padding: 20px 20px 20px 300px;
  @include mq(sp) {
    padding: 20px 10px 0 10px;
  }
}
.main .service-price table td:last-child {
  padding: 20px 300px 20px 20px;
  @include mq(sp) {
    padding: 0 10px 20px 10px;
    text-align: right;
  }
}

/* 会社案内 */
.rinen {
  text-align: center;
  @include mq(sp) {
    text-align: left;
  }
  &__copy {
    font-family: $mincho;
    font-size: 30px;
    @include mq(sp) {
      @include fz_vw(16);
    }
  }
  &__message {
    font-size: 18px;
    @include mq(sp) {
      @include fz_vw(14);
    }
  }
}
.ceo {
  @include flex();
  &__message {
    width: 65%;
    padding: 30px 50px;
    font-family: $mincho;
    font-size: 18px;
    line-height: 2;
    @include mq(sp) {
      width: auto;
      padding: 0;
      @include fz_vw(14);
      line-height: 1.5;
    }
  }
  &__photo {
    width: 30%;
    @include mq(sp) {
      width: auto;
    }
  }
}


/* ブログ */
.blog {
  &--default {
    .tag {
      margin: -20px 0 20px;
    }
    .category {
      display: inline-block;
      margin-bottom: 10px;
      @include mq(sp) {
        display: block;
      }
      a {
        display: inline-block;
        padding: 2px 15px;
        border: 1px solid $main;
        text-decoration: none;
        color: $dark-main;
      }
    }
    .more {
      text-align: right;
    }
  }
  &--index {
    .post {
      position: relative;
      margin-bottom: 80px;
      @include mq(sp) {
        margin-bottom: 30px;
      }
    }
    .category {
      position: absolute;
      top: 8px;
      right: 0;
      display: inline-block;
      @include mq(sp) {
        position: static;
        display: block;
      }
    }
    .eye-catch {
      float: left;
      margin-right: 20px;
      @include mq(sp) {
        float: none;
        margin-right: 0;
      }
      img {
        @include mq(sp) {
          width: 100%;
        }
      }
    }
  }
  &--single {
    .eye-catch {
      margin-bottom: 30px;
      img {
        @include mq(sp) {
          width: 100%;
        }
      }
    }
  }
}