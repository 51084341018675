@charset "utf-8";

$black: #505050;
$gray: #474647;/* グレー */
$light-gray: #E3E7ED;/* 薄いグレー */
$main: #5BB3FF;/* ブルー */
$sub: #FF8724;/* オレンジ */
$light-main: #90CEF0;/* ライトグリーン */
$deep-main: #3BA0FF;/* ブルー */
$dark-main: #2566BC;

$base-lh: 1.8;
$base-fs: 16px;

$marugo: "ヒラギノ丸ゴ Pro W4","Hiragino Maru Gothic Pro", "メイリオ", Meiryo, Osaka, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
$mincho: "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro","ＭＳ Ｐ明朝", "MS PMincho", serif;
$font-yu: "游ゴシック体", YuGothic, "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック", "Yu Gothic", "メイリオ", sans-serif;
$mincho-yu: "游明朝" , "Yu Mincho" , "游明朝体" , "YuMincho" , "ヒラギノ明朝 Pro W3" , "Hiragino Mincho Pro" , "HiraMinProN-W3" , "ＭＳ Ｐ明朝" , "MS PMincho" , serif;
$font-times: 'Times New Roman','Luchida Grande';
$font-round: "M PLUS Rounded 1c";
