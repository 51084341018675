@charset "utf-8";

/*--------------------------------------------------------
  テーブル
----------------------------------------------------------*/
.main {
    table {
    @include box-sizing(border-box);
    border-top: 1px solid $main;
    tr {
      border-bottom: 1px solid $main;
    }
    th {
      @include box-sizing(border-box);
      padding: 20px;
      background: $light-gray;
      color: $main;
      font-size: 16px;
      text-align: left;
      vertical-align: top;
      @include mq(sp) {
        @include fz_vw(16);
        display: block;
        width: 100%;
        padding: 15px 10px;
      }
    }
    td {
      @include box-sizing(border-box);
      padding: 20px;
      font-size: 15px;
      line-height: 1.5;
      @include mq(sp) {
        @include fz_vw(15);
        display: block;
        width: 100%;
        padding: 15px 10px;
      }
    }
  }
}

.table-scroll {
  overflow: auto;
  white-space: nowrap;
  &::-webkit-scrollbar {
    height: 5px;
  }
  &::-webkit-scrollbar-track {
    background: #F1F1F1;
  }
  &::-webkit-scrollbar-thumb {
    background: #BCBCBC;
  }
  th {
    @include mq(sp) {
      @include fz_vw(16);
      display: table-cell !important;
      width: auto !important;
      padding: 10px !important;
    }
  }
  td {
    @include mq(sp) {
      @include fz_vw(15);
      display: table-cell !important;
      width: auto !important;
      padding: 10px !important;
    }
  }
}