@charset "utf-8";

body {
  padding-top: 102px;
  @include mq(sp) {
    padding-top: 0;
  }
}
.contents {
  &--page {
    @include base-wrap--space();
  }
  &--2col {
    @include flex();
    .main {
      width: 824px;
      @include mq(sp) {
        width: auto;
      }
    }
    .side {
      width: 300px;
      @include mq(sp) {
        width: auto;
      }
    }
  }
  &--left {
    flex-direction: row-reverse !important;
  }
}

@include mq(sp) {
  .banner {
      width: auto;
      margin-left: 10px;
      margin-right: 10px;
  }
}

.base-wrap {
  width: 1200px;
  margin-left: auto;
  margin-right: auto;
  @include mq(sp) {
    width: auto;
    margin-left: 0;
    margin-right: 0;
  }
  &--space {
    width: 1200px;
    margin-left: auto;
    margin-right: auto;
    @include mq(sp) {
      width: auto;
      margin-left: 10px;
      margin-right: 10px;
    }
  }
}

.main-wrap {
  width: 920px;
  margin-left: auto;
  margin-right: auto;
  @include mq(sp) {
    width: auto;
    margin-left: 0;
    margin-right: 0;
  }
  &--space {
    width: 920px;
    margin-left: auto;
    margin-right: auto;
    @include mq(sp) {
      width: auto;
      margin-left: 10px;
      margin-right: 10px;
    }
  }
}
.screen-reader-text {
  display: none;
}
