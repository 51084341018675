@charset "UTF-8";
/* グレー */
/* 薄いグレー */
/* ブルー */
/* オレンジ */
/* ライトグリーン */
/* ブルー */
/* smartphone font-size */
/* media quary */
/* flex */
@import url("https://fonts.googleapis.com/earlyaccess/notosansjapanese.css");
@import url("https://fonts.googleapis.com/css?family=Muli");
/*
html5doctor.com Reset Stylesheet
v1.6.1
Last Updated: 2010-09-17
Author: Richard Clark - http://richclarkdesign.com
Twitter: @rich_clark
*/
html, body, div, span, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
abbr, address, cite, code,
del, dfn, em, img, ins, kbd, q, samp,
small, strong, sub, sup, var,
b, i,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, figcaption, figure,
footer, header, hgroup, menu, nav, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

body {
  line-height: 1;
}

article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block;
}

ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none;
}

a {
  margin: 0;
  padding: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
}

/* change colours to suit your needs */
ins {
  background-color: #ff9;
  color: #000;
  text-decoration: none;
}

/* change colours to suit your needs */
mark {
  background-color: #ff9;
  color: #000;
  font-style: italic;
  font-weight: bold;
}

del {
  text-decoration: line-through;
}

abbr[title], dfn[title] {
  border-bottom: 1px dotted;
  cursor: help;
}

table {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
}

/* change border colour to suit your needs */
hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #cccccc;
  margin: 1em 0;
  padding: 0;
}

input, select {
  vertical-align: middle;
}

html {
  width: 100%;
}

body {
  width: 100%;
  margin: 0;
  padding: 0;
  font-family: 'ヒラギノ角ゴ Pro W3', 'Hiragino Kaku Gothic Pro', メイリオ, Meiryo, 'ＭＳ Ｐゴシック', 'MS PGothic', sans-serif;
  font-style: normal;
  font-weight: normal;
  color: #505050;
  -webkit-text-size-adjust: none;
  /* -webkit-font-smoothing: antialiased; */
  font-size: 16px;
  line-height: 1.8;
  overflow-x: hidden;
}

@media screen and (max-width: 959px) and (min-width: 599px) {
  body {
    width: 1240px;
  }
}

@media screen and (min-width: 960px) {
  body {
    min-width: 1240px;
  }
}

a {
  color: #505050;
  text-decoration: none;
  transition: .2s;
}

a:hover {
  transition: .2s;
}

img {
  max-width: 100%;
  height: auto;
}

a:hover img {
  opacity: 0.9;
}

.text {
  line-height: 1.8;
}

.hidden {
  display: none !important;
  visibility: hidden;
}

.clearfix:before, .clearfix:after {
  content: "";
  display: table;
}

.clearfix:after {
  clear: both;
}

.clear {
  clear: both;
}

/* show only  SP/PC
-------------------------------*/
@media screen and (min-width: 960px) {
  .sp {
    display: none !important;
  }
}

@media screen and (max-width: 959px) and (min-width: 599px) {
  .sp {
    display: none !important;
  }
}

@media screen and (min-width: 960px) {
  .sp-s {
    display: none !important;
  }
}

@media screen and (max-width: 959px) and (min-width: 599px) {
  .sp-s {
    display: none !important;
  }
}

@media screen and (max-width: 599px) {
  .sp-s {
    display: block !important;
  }
}

@media screen and (max-width: 599px) {
  .pc {
    display: none !important;
  }
}

@media screen and (max-width: 959px) and (min-width: 599px) {
  .pc-s {
    display: block !important;
  }
}

@media screen and (max-width: 599px) {
  .pc-s {
    display: none !important;
  }
}

/* @group float
-------------------------------- */
.right {
  float: right;
}

.left {
  float: left;
}

.img-left {
  float: left;
  margin: 0 20px 10px 0;
}

.img-right {
  float: right;
  margin: 0 0 10px 20px;
}

/* @text-align
-------------------------------- */
.ta-right {
  text-align: right !important;
}

.ta-center {
  text-align: center !important;
}

.ta-left {
  text-align: left !important;
}

/* @vertical-align
-------------------------------- */
.va-top {
  vertical-align: top !important;
}

.va-bottom {
  vertical-align: bottom !important;
}

.va-middle {
  vertical-align: middle !important;
}

/* @group color
-------------------------------- */
.red {
  color: #EA5413 !important;
}

.blue {
  color: #0099ff !important;
}

.gray {
  color: #666 !important;
}

.pink {
  color: #C60070 !important;
}

.peach {
  color: #F86363 !important;
}

.green {
  color: #89B929 !important;
}

/* @group margin
-------------------------------- */
.m0 {
  margin: 0 !important;
}

.mb0 {
  margin-bottom: 0 !important;
}

.mb10 {
  margin-bottom: 10px !important;
}

.mb20 {
  margin-bottom: 20px !important;
}

.mb30 {
  margin-bottom: 30px !important;
}

.mb40 {
  margin-bottom: 40px !important;
}

.mb50 {
  margin-bottom: 50px !important;
}

.mb60 {
  margin-bottom: 60px !important;
}

.mb70 {
  margin-bottom: 70px !important;
}

.mb80 {
  margin-bottom: 80px !important;
}

.mb90 {
  margin-bottom: 90px !important;
}

.mb100 {
  margin-bottom: 100px !important;
}

.mt0 {
  margin-top: 0px !important;
}

.mt10 {
  margin-top: 10px !important;
}

.mt20 {
  margin-top: 20px !important;
}

.mt30 {
  margin-top: 30px !important;
}

.mt40 {
  margin-top: 40px !important;
}

.mt50 {
  margin-top: 50px !important;
}

.mt60 {
  margin-top: 60px !important;
}

.mt70 {
  margin-top: 70px !important;
}

.mt80 {
  margin-top: 80px !important;
}

.mt90 {
  margin-top: 90px !important;
}

.mt100 {
  margin-top: 100px !important;
}

.mr10 {
  margin-right: 10px !important;
}

.mr20 {
  margin-right: 20px !important;
}

.mr30 {
  margin-right: 30px !important;
}

.mr40 {
  margin-right: 40px !important;
}

.ml10 {
  margin-left: 10px !important;
}

.ml20 {
  margin-left: 20px !important;
}

.ml30 {
  margin-left: 30px !important;
}

.ml40 {
  margin-left: 40px !important;
}

/* @font size
-------------------------------- */
.fs10 {
  font-size: 10px !important;
}

.fs11 {
  font-size: 11px !important;
}

.fs12 {
  font-size: 12px !important;
}

.fs13 {
  font-size: 13px !important;
}

.fs14 {
  font-size: 14px !important;
}

.fs15 {
  font-size: 15px !important;
}

.fs16 {
  font-size: 16px !important;
}

.fs17 {
  font-size: 17px !important;
}

.fs18 {
  font-size: 18px !important;
}

.fs19 {
  font-size: 19px !important;
}

.fs20 {
  font-size: 20px !important;
}

.fs21 {
  font-size: 21px !important;
}

.fs22 {
  font-size: 22px !important;
}

.fs23 {
  font-size: 23px !important;
}

.fs24 {
  font-size: 24px !important;
}

.fs25 {
  font-size: 25px !important;
}

.fs26 {
  font-size: 26px !important;
}

.fs27 {
  font-size: 27px !important;
}

.fs28 {
  font-size: 28px !important;
}

.fs29 {
  font-size: 29px !important;
}

.fs30 {
  font-size: 30px !important;
}

.fs31 {
  font-size: 31px !important;
}

.fs32 {
  font-size: 32px !important;
}

.fs34 {
  font-size: 34px !important;
}

.fs36 {
  font-size: 36px !important;
}

.fs38 {
  font-size: 38px !important;
}

.fs48 {
  font-size: 48px !important;
}

.fs56 {
  font-size: 56px !important;
}

.fs64 {
  font-size: 64px !important;
}

/* @font-weight
-------------------------------- */
.fw-bold {
  font-weight: bold !important;
}

/* @border
-------------------------------- */
.bb-none {
  border-bottom: 0 !important;
}

.bl-none {
  border-left: 0 !important;
}

.br-none {
  border-right: 0 !important;
}

.bt-none {
  border-top: 0 !important;
}

/* @status font color
-------------------------------- */
.required {
  color: #c00;
}

.error-message {
  color: #c00;
}

/* @wp image
-------------------------------- */
.alignleft {
  float: left;
  margin: 0 20px 10px 0;
}

.alignright {
  float: right;
  margin: 0 0 10px 20px;
}

.aligncenter {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

/* @flex
-------------------------------- */
.flex {
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
}

@media screen and (max-width: 599px) {
  .flex {
    display: block;
    width: auto;
  }
}

.flex-sp {
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
}

/* @font-family
-------------------------------- */
.mincho {
  font-family: "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ Ｐ明朝", "MS PMincho", serif;
}

.yu {
  font-family: "游ゴシック体", YuGothic, "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック", "Yu Gothic", "メイリオ", sans-serif;
}

body {
  padding-top: 102px;
}

@media screen and (max-width: 599px) {
  body {
    padding-top: 0;
  }
}

.contents--page {
  width: 1200px;
  margin-left: auto;
  margin-right: auto;
}

@media screen and (max-width: 599px) {
  .contents--page {
    width: auto;
    margin-left: 10px;
    margin-right: 10px;
  }
}

.contents--2col {
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
}

@media screen and (max-width: 599px) {
  .contents--2col {
    display: block;
    width: auto;
  }
}

.contents--2col .main {
  width: 824px;
}

@media screen and (max-width: 599px) {
  .contents--2col .main {
    width: auto;
  }
}

.contents--2col .side {
  width: 300px;
}

@media screen and (max-width: 599px) {
  .contents--2col .side {
    width: auto;
  }
}

.contents--left {
  flex-direction: row-reverse !important;
}

@media screen and (max-width: 599px) {
  .banner {
    width: auto;
    margin-left: 10px;
    margin-right: 10px;
  }
}

.base-wrap {
  width: 1200px;
  margin-left: auto;
  margin-right: auto;
}

@media screen and (max-width: 599px) {
  .base-wrap {
    width: auto;
    margin-left: 0;
    margin-right: 0;
  }
}

.base-wrap--space {
  width: 1200px;
  margin-left: auto;
  margin-right: auto;
}

@media screen and (max-width: 599px) {
  .base-wrap--space {
    width: auto;
    margin-left: 10px;
    margin-right: 10px;
  }
}

.main-wrap {
  width: 920px;
  margin-left: auto;
  margin-right: auto;
}

@media screen and (max-width: 599px) {
  .main-wrap {
    width: auto;
    margin-left: 0;
    margin-right: 0;
  }
}

.main-wrap--space {
  width: 920px;
  margin-left: auto;
  margin-right: auto;
}

@media screen and (max-width: 599px) {
  .main-wrap--space {
    width: auto;
    margin-left: 10px;
    margin-right: 10px;
  }
}

.screen-reader-text {
  display: none;
}

/*--------------------------------------------------------
  ヘッダー
----------------------------------------------------------*/
/* ヘッダー */
.header {
  position: fixed;
  top: 0;
  left: 0;
  background: #fff;
  width: 100%;
  padding: 12px 0 10px;
  box-shadow: 0 1px 4px 2px rgba(0, 0, 0, 0.1);
  line-height: 1.5;
  z-index: 100;
}

@media screen and (max-width: 599px) {
  .header {
    position: static;
    box-shadow: none;
    padding: 0;
    z-index: 100;
  }
}

.header__inner {
  width: 1200px;
  margin-left: auto;
  margin-right: auto;
}

@media screen and (max-width: 599px) {
  .header__inner {
    width: auto;
    margin-left: 0;
    margin-right: 0;
  }
}

.header__logo {
  display: inline-block;
}

@media screen and (max-width: 599px) {
  .header__logo {
    padding: 10px;
  }
}

.header__logo img {
  width: 400px;
  height: 80px;
  vertical-align: top;
}

@media screen and (max-width: 599px) {
  .header__logo img {
    width: 240px;
    height: 53px;
  }
}

.header__menu {
  display: none;
}

.gnavi {
  float: right;
  padding: 15px 0;
}

@media screen and (max-width: 599px) {
  .gnavi {
    display: none;
    float: none;
    padding: 0;
  }
}

.gnavi__list__item {
  float: left;
  height: 40px;
}

@media screen and (max-width: 599px) {
  .gnavi__list__item {
    float: none;
    height: auto;
    background: #666666;
  }
}

.gnavi__list__item a {
  display: block;
  margin: 0 15px;
  padding: 0 10px;
  color: #9f832e;
  text-decoration: none;
  font-family: 'Sorts Mill Goudy', serif;
  font-size: 10px;
}

@media screen and (max-width: 599px) {
  .gnavi__list__item a {
    font-size: 12px;
    font-size: 3.2vw;
    width: auto;
    margin: 0;
    padding: 16px 10px 10px 40px;
    border-bottom: 1px solid #808080;
    background: url(../img/sp/icn_arrow.png) 20px 18px no-repeat;
    background-size: 7px 14px;
    text-align: left;
  }
}

.gnavi__list__item a:hover {
  border-bottom: 2px solid #90CEF0;
}

.gnavi__list__item a b {
  padding-left: 4px;
  font-size: 14px;
  font-weight: normal;
}

.gnavi__list__item a span {
  display: block;
  color: #333333;
  font-family: 'ヒラギノ角ゴ Pro W3', 'Hiragino Kaku Gothic Pro', メイリオ, Meiryo, 'ＭＳ Ｐゴシック', 'MS PGothic', sans-serif;
  font-size: 16px;
}

@media screen and (max-width: 599px) {
  .gnavi__list__item a span {
    display: inline;
    margin-right: 10px;
    color: #fff;
  }
}

.gnavi__list__item:last-child a {
  margin-right: 0;
}

#BtnMenu {
  display: none;
}

@media screen and (max-width: 599px) {
  #BtnMenu {
    display: block;
    width: 24px;
    position: absolute;
    top: 15px;
    right: 10px;
  }
}

/*--------------------------------------------------------
  フッター
----------------------------------------------------------*/
/* MAP */
.footer__map__inner {
  height: 450px;
}

/* コンタクト */
.footer__contact {
  margin-bottom: 30px;
  background: #2566BC;
}

@media screen and (max-width: 599px) {
  .footer__contact {
    margin-bottom: 10px;
  }
}

.footer__contact__inner {
  width: 1200px;
  margin-left: auto;
  margin-right: auto;
  padding: 40px 0;
  text-align: center;
  font-size: 36px;
}

@media screen and (max-width: 599px) {
  .footer__contact__inner {
    width: auto;
    margin-left: 10px;
    margin-right: 10px;
  }
}

@media screen and (max-width: 599px) {
  .footer__contact__inner {
    font-size: 20px;
  }
}

.footer__contact__title {
  margin-right: 40px;
  color: #90CEF0;
  font-family: 'Oswald', sans-serif;
  letter-spacing: 3px;
}

@media screen and (max-width: 599px) {
  .footer__contact__title {
    margin-right: 20px;
  }
}

.footer__contact__tel {
  color: #fff;
  font-family: 'Oswald', sans-serif;
  letter-spacing: 3px;
}

.footer__contact__tel a {
  color: #fff;
}

.footer__contact__time {
  color: #000;
  font-size: 16px;
}

/* ロゴナビ */
.footer__inner {
  width: 1200px;
  margin-left: auto;
  margin-right: auto;
}

@media screen and (max-width: 599px) {
  .footer__inner {
    width: auto;
    margin-left: 10px;
    margin-right: 10px;
  }
}

.footer__left {
  display: inline-block;
  padding-left: 50px;
}

@media screen and (max-width: 599px) {
  .footer__left {
    display: block;
    padding-left: 0;
    margin-bottom: 20px;
  }
}

@media screen and (max-width: 599px) {
  .footer__logo {
    width: 50%;
    margin: 20px auto 0;
  }
}

.footer__logo img {
  width: 180px;
  height: 60px;
}

.footer__address {
  font-size: 13px;
  line-height: 1.2;
}

@media screen and (max-width: 599px) {
  .footer__address {
    text-align: center;
  }
}

.footer__address__name {
  margin-bottom: 8px;
  font-size: 18px;
}

.footer__address__address {
  margin-bottom: 5px;
}

.footer__address__phone {
  margin-bottom: 5px;
}

.fnavi {
  float: right;
  padding-top: 18px;
}

@media screen and (max-width: 599px) {
  .fnavi {
    float: none;
  }
}

@media screen and (max-width: 599px) {
  .fnavi__list {
    display: flex;
    flex-wrap: wrap;
  }
}

.fnavi__list__item {
  float: left;
  padding: 15px 10px;
  white-space: nowrap;
}

@media screen and (max-width: 599px) {
  .fnavi__list__item {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    float: none;
    width: 50%;
    padding: 5px 10px;
    border: 1px solid #fff;
    background: #90CEF0;
  }
}

.fnavi__list__item a {
  padding: 0 10px 8px;
  color: #333;
  font-size: 13px;
  text-decoration: none;
}

@media screen and (min-width: 960px) {
  .fnavi__list__item a:hover {
    border-bottom: 2px solid #90CEF0;
  }
}

.copyright {
  clear: both;
  padding: 50px 0 20px;
  color: #999;
  font-size: 15px;
  text-align: center;
}

@media screen and (max-width: 599px) {
  .copyright {
    font-size: 12px;
    font-size: 3.2vw;
    padding-top: 20px;
  }
}

/* ページ上へ戻る */
#TopLink {
  position: fixed;
  bottom: 50px;
  left: 50%;
  z-index: 10000;
  margin-left: 540px;
  text-align: right;
  text-decoration: none;
  font-family: 'Oswald', sans-serif;
  cursor: pointer;
  opacity: 0.7;
}

@media screen and (max-width: 599px) {
  #TopLink {
    display: none;
  }
}

#TopLink img:hover {
  opacity: 0.5;
}

@media screen and (max-width: 599px) {
  #TopLink img:hover {
    opacity: 1;
  }
}

/*--------------------------------------------------------
	サイドナビ
----------------------------------------------------------*/
/* ローカルナビ */
.widget-local-navi > ul {
  margin: 0 -2px;
  padding: 0 2px 15px;
}

.widget-local-navi > ul > li {
  border-bottom: 1px dotted #ccc;
}

.widget-local-navi li:last-child {
  border-bottom: none;
}

.widget-local-navi li a {
  display: block;
  padding: 16px 10px 16px 16px;
  text-decoration: none;
  color: #333;
}

.widget-local-navi li ahover {
  color: #CC3300;
}

/* ローカルナビ2層 */
.widget-local-navi ul ul {
  margin-left: 16px;
  margin-bottom: 10px;
}

.widget-local-navi ul ul li a {
  padding: 0px 10px 5px 20px;
  font-size: 13px;
}

/* ウィジェット共通 */
.widget {
  margin-bottom: 50px;
}

.widget h2 {
  margin-bottom: 20px;
  padding: 10px 15px;
  background: #90CEF0;
  color: #fff;
  font-size: 15px;
}

.widget img {
  max-width: 100%;
}

.widget ul li {
  position: relative;
  margin-left: 5px;
  margin-bottom: 10px;
  padding-left: 15px;
}

.widget ul li::before {
  position: absolute;
  top: 14px;
  left: 0;
  content: "";
  display: block;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: #90CEF0;
}

.widget ul li a {
  color: #333333;
  text-decoration: none;
}

/* ブログウィジェット */
.blog-widget li {
  padding: 10px 0;
  border-bottom: 1px dashed #ccc;
  line-height: 1.2;
}

.blog-widget li a {
  color: #1D4C86;
  text-decoration: none;
}

.blog-widget li a:hover {
  color: #2566BC;
  text-decoration: underline;
}

/* ブログ カレンダー */
.widget-blog-calendar table {
  border-collapse: collapse;
  box-sizing: border-box;
}

.widget-blog-calendar table th {
  padding: 6px 0;
  border: 1px solid #5BB3FF;
  font-size: 12px;
}

.widget-blog-calendar table td {
  padding: 6px 0;
  color: #aaa;
  text-align: center;
  border: 1px solid #5BB3FF;
  font-size: 12px;
}

.widget-blog-calendar table a {
  color: #2566BC;
  text-decoration: underline;
}

.widget-blog-calendar table center {
  color: #000;
  font-size: 16px;
}

/*--------------------------------------------------------
  スマホナビゲーション
----------------------------------------------------------*/
/* スマホグローバルナビ開閉ボタン */
@media screen and (max-width: 599px) {
  .nav-sp {
    display: none;
  }
}

.header__menu {
  display: none;
  position: absolute;
  top: 5px;
  right: 5px;
  width: 50px;
}

@media screen and (max-width: 599px) {
  .header__menu {
    display: block;
  }
}

#panel-btn {
  display: inline-block;
  position: relative;
  width: 50px;
  height: 50px;
  border-radius: 3px;
  background: #2566BC;
  text-decoration: none;
  text-align: center;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

#panel-btn .close {
  background: transparent;
}

#panel-btn .close::before, #panel-btn .close::after {
  margin-top: 0;
}

#panel-btn .close::before {
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

#panel-btn .close::after {
  -webkit-transform: rotate(-135deg);
  transform: rotate(-135deg);
}

#panel-btn-icon {
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 30px;
  height: 4px;
  margin: -2px 0 0 -15px;
  background: #fff;
  -webkit-transition: .2s;
  transition: .2s;
}

#panel-btn-icon::before, #panel-btn-icon::after {
  display: block;
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  width: 30px;
  height: 4px;
  background: #fff;
  -webkit-transition: .3s;
  transition: .3s;
}

#panel-btn-icon::before {
  margin-top: -12px;
}

#panel-btn-icon::after {
  margin-top: 8px;
}

#BtnClose {
  display: none;
  padding: 20px 0 20px;
  background: #efefef;
  color: #2566BC;
  font-weight: bold;
  letter-spacing: .1em;
  text-align: center;
  font-size: 20px;
  font-size: 5.33333vw;
}

@media screen and (max-width: 599px) {
  #BtnClose {
    display: block;
  }
}

/*--------------------------------------------------------
	ボタン
----------------------------------------------------------*/
.button {
  display: inline-block;
  margin: 10px 0;
  padding: 15px 50px;
  border: 3px solid #2566BC;
  border-radius: 40px;
  background: #2566BC;
  color: #fff;
  font-size: 18px;
  text-align: center;
  text-decoration: none;
  line-height: 1;
}

@media screen and (max-width: 599px) {
  .button {
    font-size: 16px;
    font-size: 4.26667vw;
    padding: 10px 40px;
  }
}

.button:hover {
  background: #fff;
  color: #2566BC;
  text-decoration: none;
}

.btn-small {
  display: inline-block;
  padding: 5px 20px 5px 40px;
  border-radius: 4px;
  background: #2566BC url(../img/common/icn_arrow01.png) 10px center no-repeat;
  color: #90CEF0;
  font-size: 14px;
  letter-spacing: 2px;
}

.btn-midium {
  width: 230px;
  padding: 20px 40px 20px 60px;
  border-radius: 4px;
  background: #2566BC url(../img/common/icn_arrow01.png) 10px center no-repeat;
  color: #90CEF0;
  font-size: 20px;
  letter-spacing: 2px;
}

.btn-big {
  display: inline-block;
  width: 380px;
  padding: 30px 10px 30px 20px;
  border-radius: 4px;
  background: #000 url(../img/common/icn_arrow01.png) 10px center no-repeat;
  font-size: 24px;
  letter-spacing: 2px;
}

.btn-wrap {
  text-align: center;
}

.btn-wrap a {
  display: inline-block;
  margin: 10px 0;
  padding: 20px 80px;
  border: 3px solid #FF8724;
  border-radius: 4px;
  background: #FF8724;
  color: #fff;
  font-size: 30px;
  text-align: center;
  text-decoration: none;
}

@media screen and (max-width: 599px) {
  .btn-wrap a {
    font-size: 18px;
    font-size: 4.8vw;
    display: block;
    padding: 10px;
  }
}

.btn-wrap a:hover {
  background: #fff;
  color: #FF8724;
  text-decoration: none;
}

/*--------------------------------------------------------
  タイトル
----------------------------------------------------------*/
/* 見出し */
.title {
  margin: 50px 0;
  line-height: 1;
  text-align: center;
}

@media screen and (max-width: 599px) {
  .title {
    margin-bottom: 20px;
    margin-top: 30px;
  }
}

.title .main-title1 {
  display: inline-block;
  margin-bottom: 10px;
  color: #3BA0FF;
  font-size: 50px;
  font-family: 'Oswald', sans-serif;
  letter-spacing: 2px;
}

@media screen and (max-width: 599px) {
  .title .main-title1 {
    font-size: 25px;
  }
}

.title .main-title1::before {
  display: block;
  width: 100px;
  content: '';
  margin: 0 auto;
  padding-top: 30px;
  border-top: 1px solid #999999;
}

@media screen and (max-width: 599px) {
  .title .main-title1::before {
    padding-top: 20px;
  }
}

.title .sub-title {
  font-size: 18px;
  color: #999999;
}

@media screen and (max-width: 599px) {
  .title .sub-title {
    margin-bottom: 30px;
    font-size: 12px;
  }
}

.title .main-title2 {
  display: inline-block;
  margin-bottom: 10px;
  color: #3BA0FF;
  font-size: 50px;
  font-family: 'Oswald', sans-serif;
  letter-spacing: 2px;
}

@media screen and (max-width: 599px) {
  .title .main-title2 {
    font-size: 25px;
  }
}

#ToolBar a.title {
  margin: 0;
}

/* 下層ページの見出し */
.page h2 {
  position: relative;
  margin-bottom: 30px;
  padding-bottom: 15px;
  border-bottom: 2px solid #90CEF0;
  font-size: 30px;
  font-weight: bold;
  line-height: 1.3;
}

@media screen and (max-width: 599px) {
  .page h2 {
    font-size: 20px;
    font-size: 5.33333vw;
  }
}

.page h2::before {
  position: absolute;
  bottom: -5px;
  left: 0;
  content: "";
  display: block;
  width: 300px;
  height: 4px;
  background: #90CEF0;
}

@media screen and (max-width: 599px) {
  .page h2::before {
    width: 180px;
  }
}

.page h3 {
  margin: 40px 0 30px;
  padding-bottom: 15px;
  border-bottom: 1px dotted #999;
  font-size: 24px;
}

@media screen and (max-width: 599px) {
  .page h3 {
    font-size: 16px;
    font-size: 4.26667vw;
  }
}

.page h3 a {
  text-decoration: none;
  color: #333;
}

.page h3 a:hover {
  text-decoration: underline;
  color: #888;
}

.page h4 {
  margin: 30px 0 20px;
  font-size: 18px;
  font-weight: bold;
}

.page h5 {
  margin-top: 20px;
  font-size: 18px;
}

.notice {
  color: #777;
  font-size: 13px;
}

/*--------------------------------------------------------
  ぱんくず
----------------------------------------------------------*/
.breadcrumbs {
  width: 1200px;
  margin-left: auto;
  margin-right: auto;
  margin: 20px auto 30px;
  padding: 0 10px;
  font-size: 13px;
  box-sizing: border-box;
}

@media screen and (max-width: 599px) {
  .breadcrumbs {
    width: auto;
    margin-left: 10px;
    margin-right: 10px;
  }
}

@media screen and (max-width: 599px) {
  .breadcrumbs {
    display: none;
  }
}

/*--------------------------------------------------------
  ページネーション
----------------------------------------------------------*/
/* ブログ　pagination */
.pagination {
  margin: 60px 0;
  text-align: center;
  font-size: 12px;
  font-size: 1.2rem;
}

.pagination span.disabled,
.pagination span.prev a,
.pagination span.next a {
  margin: 0 20px;
  color: #333;
}

.pagination span.current,
.pagination span.number a {
  margin: 0 4px;
  padding: 7px 10px;
  background: #dedede;
  color: #fff;
  text-decoration: none !important;
}

.pagination span.current,
.pagination span.number a:hover {
  background: #666;
}

.pagination a:active {
  background: #666;
}

/*--------------------------------------------------------
  コンテンツナビ
----------------------------------------------------------*/
.contents-navi {
  margin: 50px 0;
  text-align: center;
}

.contents-navi a {
  display: inline-block;
  padding: 11px 14px;
  color: #000;
}

.contents-navi a.prev-link, .contents-navi a.next-link {
  background: #ddd;
}

.contents-navi a:hover {
  color: #333;
}

.contents-navi a:hover.prev-link, .contents-navi a:hover.next-link {
  background: #eee;
}

/*--------------------------------------------------------
  ページ上部へ戻るボタン
----------------------------------------------------------*/
.page-top {
  background: #999999;
  text-align: center;
  cursor: pointer;
}

/*--------------------------------------------------------
  テーブル
----------------------------------------------------------*/
.main table {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  border-top: 1px solid #5BB3FF;
}

.main table tr {
  border-bottom: 1px solid #5BB3FF;
}

.main table th {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  padding: 20px;
  background: #E3E7ED;
  color: #5BB3FF;
  font-size: 16px;
  text-align: left;
  vertical-align: top;
}

@media screen and (max-width: 599px) {
  .main table th {
    font-size: 16px;
    font-size: 4.26667vw;
    display: block;
    width: 100%;
    padding: 15px 10px;
  }
}

.main table td {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  padding: 20px;
  font-size: 15px;
  line-height: 1.5;
}

@media screen and (max-width: 599px) {
  .main table td {
    font-size: 15px;
    font-size: 4vw;
    display: block;
    width: 100%;
    padding: 15px 10px;
  }
}

.table-scroll {
  overflow: auto;
  white-space: nowrap;
}

.table-scroll::-webkit-scrollbar {
  height: 5px;
}

.table-scroll::-webkit-scrollbar-track {
  background: #F1F1F1;
}

.table-scroll::-webkit-scrollbar-thumb {
  background: #BCBCBC;
}

@media screen and (max-width: 599px) {
  .table-scroll th {
    font-size: 16px;
    font-size: 4.26667vw;
    display: table-cell !important;
    width: auto !important;
    padding: 10px !important;
  }
}

@media screen and (max-width: 599px) {
  .table-scroll td {
    font-size: 15px;
    font-size: 4vw;
    display: table-cell !important;
    width: auto !important;
    padding: 10px !important;
  }
}

/*--------------------------------------------------------
	icon
----------------------------------------------------------*/
/*--------------------------------------------------------
	リスト
----------------------------------------------------------*/
.page ul {
  margin: 10px 0 30px 1em;
}

.page ul > li {
  position: relative;
  margin-bottom: 10px;
  padding: 0 0 0 16px;
}

.page ul > li::before {
  position: absolute;
  top: 6px;
  left: 0;
  content: "";
  display: block;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: #2566BC;
}

.page ul > li ul li {
  padding: 0;
}

.page ul > li ul li::before {
  display: none;
}

.page ol {
  margin: 10px 0 30px 1em;
  list-style: none;
}

.page ol > li {
  position: relative;
  margin-bottom: 10px;
  padding: 0 0 0 22px;
}

.page ol > li::before {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  color: #2566BC;
}

.page ol > li:nth-child(1)::before {
  content: "1. ";
}

.page ol > li:nth-child(2)::before {
  content: "2. ";
}

.page ol > li:nth-child(3)::before {
  content: "3. ";
}

.page ol > li:nth-child(4)::before {
  content: "4. ";
}

.page ol > li:nth-child(5)::before {
  content: "5. ";
}

.page ol > li:nth-child(6)::before {
  content: "6. ";
}

.page ol > li:nth-child(7)::before {
  content: "7. ";
}

.page ol > li:nth-child(8)::before {
  content: "8. ";
}

.page ol > li:nth-child(9)::before {
  content: "9. ";
}

.page ol > li > ol {
  margin-left: 0;
}

.page ol > li > ol > li {
  position: relative;
  margin-bottom: 10px;
  padding: 0 0 0 26px;
}

.page ol > li > ol > li::before {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  color: #2566BC;
}

.page ol > li > ol > li:nth-child(1)::before {
  content: "(1)";
}

.page ol > li > ol > li:nth-child(2)::before {
  content: "(2)";
}

.page ol > li > ol > li:nth-child(3)::before {
  content: "(3)";
}

.page ol > li > ol > li:nth-child(4)::before {
  content: "(4)";
}

.page ol > li > ol > li:nth-child(5)::before {
  content: "(5)";
}

.page ol > li > ol > li:nth-child(6)::before {
  content: "(6)";
}

.page ol > li > ol > li:nth-child(7)::before {
  content: "(7)";
}

.page ol > li > ol > li:nth-child(8)::before {
  content: "(8)";
}

.page ol > li > ol > li:nth-child(9)::before {
  content: "(9)";
}

.page ol > li > ol > li > ol > li {
  position: relative;
  margin-bottom: 10px;
  padding: 0 0 0 22px;
}

.page ol > li > ol > li > ol > li::before {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  color: #2566BC;
}

.page ol > li > ol > li > ol > li:nth-child(1)::before {
  content: "①";
}

.page ol > li > ol > li > ol > li:nth-child(2)::before {
  content: "②";
}

.page ol > li > ol > li > ol > li:nth-child(3)::before {
  content: "③";
}

.page ol > li > ol > li > ol > li:nth-child(4)::before {
  content: "④";
}

.page ol > li > ol > li > ol > li:nth-child(5)::before {
  content: "⑤";
}

.page ol > li > ol > li > ol > li:nth-child(6)::before {
  content: "⑥";
}

.page ol > li > ol > li > ol > li:nth-child(7)::before {
  content: "⑦";
}

.page ol > li > ol > li > ol > li:nth-child(8)::before {
  content: "⑧";
}

.page ol > li > ol > li > ol > li:nth-child(9)::before {
  content: "⑨";
}

/*--------------------------------------------------------
  フォーム
----------------------------------------------------------*/
@media screen and (max-width: 599px) {
  .form-table tr {
    margin-bottom: 1.5em;
  }
}

.form-table tr th {
  width: 30%;
  vertical-align: top;
}

@media screen and (max-width: 599px) {
  .form-table tr th {
    display: block;
    width: auto;
    padding: 5px 10px;
    background: #90CEF0;
    color: #2566BC;
  }
}

@media screen and (max-width: 599px) {
  .form-table tr td {
    display: block;
    width: auto;
  }
}

.form-table input[type=text] {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  width: 100%;
  margin: 0 10px 4px 0;
  padding: 8px;
  border: 1px solid #90CEF0;
  font-size: 16px;
}

.form-table .checkbox {
  font-size: 16px;
}

.form-table textarea {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  width: 100%;
  padding: 8px;
  border: 1px solid #90CEF0;
  font-size: 16px;
}

.form-table select {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  position: relative;
  margin: 0 0 4px 0;
  border: 1px solid #90CEF0;
  font-size: 16px;
}

.form-table a {
  color: #2566BC;
  text-decoration: underline;
}

.form-table ul li {
  padding-left: 0;
}

.form-table ul li::before {
  display: none;
}

.form-s {
  width: 24% !important;
}

.form-m {
  width: 36% !important;
}

.form-l {
  width: 90%;
}

@media screen and (max-width: 599px) {
  .form-l {
    width: 100%;
  }
}

.required {
  position: relative;
  top: -1px;
  display: inline-block;
  margin-left: 5px;
  padding: 1px 4px;
  border-radius: 4px;
  background: #c00;
  color: #fff;
  font-size: 12px;
}

.submit {
  margin: 30px 0;
  text-align: center;
}

.submit .form-submit {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  min-width: 150px;
  padding: 15px 20px;
  border: none;
  background: #FF8724;
  color: #fff;
  cursor: pointer;
  font-size: 18px;
  text-align: center;
}

.mail-description {
  margin-bottom: 10px;
}

.mail-description span {
  color: #5BB3FF;
}

.mail-description span a {
  color: #5BB3FF;
}

.mail-before-attachment {
  margin-right: 10px;
}

.mail-after-attachment {
  margin-right: 10px;
}

#flashMessage {
  margin-bottom: 10px;
}

.alert-message {
  color: #c00;
}

.form-back {
  margin-top: 50px;
}

/*--------------------------------------------------------
  メインイメージ
----------------------------------------------------------*/
.mainimage img {
  width: 100%;
}

/*--------------------------------------------------------
	トップページ
----------------------------------------------------------*/
/* トップページ　SERVICE */
.top-service {
  width: 1200px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 70px;
}

@media screen and (max-width: 599px) {
  .top-service {
    width: auto;
    margin-left: 10px;
    margin-right: 10px;
  }
}

.top-service__main {
  margin-bottom: 30px;
}

.top-service__main:before, .top-service__main:after {
  content: "";
  display: table;
}

.top-service__main:after {
  clear: both;
}

.top-service__main__image {
  float: left;
  width: 50%;
  height: 220px;
  margin-right: 30px;
  overflow: hidden;
}

@media screen and (max-width: 599px) {
  .top-service__main__image {
    float: none;
    width: auto;
    height: auto;
    margin-right: 0;
  }
}

.top-service__main__image img {
  width: 100%;
}

.top-service ul {
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
  margin-bottom: 30px;
}

@media screen and (max-width: 599px) {
  .top-service ul {
    display: block;
    width: auto;
  }
}

.top-service ul li {
  width: 32%;
}

@media screen and (max-width: 599px) {
  .top-service ul li {
    width: auto;
    margin-bottom: 30px;
  }
}

.top-service ul li:last-child {
  margin-right: 0;
}

.top-service ul li img {
  margin-bottom: 20px;
}

.top-service h3 {
  margin: 0 0 20px;
  color: #2566BC;
  font-size: 30px;
  line-height: 1.2;
}

@media screen and (max-width: 599px) {
  .top-service h3 {
    font-size: 18px;
    font-size: 4.8vw;
  }
}

.top-service h3 a {
  color: #2566BC;
  font-weight: bold;
}

.top-service p {
  color: #666666;
}

.top-service .btn-more {
  text-align: right;
}

@media screen and (max-width: 599px) {
  .top-service .btn-more {
    text-align: center;
  }
}

/* トップページ　FEED */
.top-news ul {
  margin-bottom: 20px;
}

.top-news ul li {
  margin-bottom: 12px;
}

.top-news ul li:before, .top-news ul li:after {
  content: "";
  display: table;
}

.top-news ul li:after {
  clear: both;
}

.top-news ul li .date {
  display: block;
  margin-left: 80px;
  margin-bottom: 10px;
  padding: 9px 0;
  padding-left: 25px;
  border-left: 1px solid #CCCCCC;
  font-size: 15px;
}

.top-news .btn-more {
  text-align: right;
}

.top-blog {
  width: 1200px;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  margin-bottom: 80px;
  padding: 0 30px 20px;
  border: 4px solid #CCCCCC;
}

@media screen and (max-width: 599px) {
  .top-blog {
    width: auto;
    margin-left: 10px;
    margin-right: 10px;
  }
}

@media screen and (max-width: 599px) {
  .top-blog {
    padding: 0 10px 10px;
  }
}

.top-blog ul {
  display: flex;
}

@media screen and (max-width: 599px) {
  .top-blog ul {
    display: block;
  }
}

.top-blog ul li {
  width: 33%;
  margin-bottom: 30px;
  margin-right: 1%;
}

@media screen and (max-width: 599px) {
  .top-blog ul li {
    width: auto;
  }
}

.top-blog ul li:nth-child(4n) {
  margin-right: 0;
}

.top-blog ul li a {
  display: block;
  padding: 4px;
}

.top-blog ul li a:before, .top-blog ul li a:after {
  content: "";
  display: table;
}

.top-blog ul li a:after {
  clear: both;
}

.top-blog ul li a:hover {
  background: #E3E7ED;
}

.top-blog ul li .thumbnail {
  float: left;
  width: 160px;
  margin-right: 10px;
}

.top-blog ul li .thumbnail img {
  width: 100%;
  vertical-align: middle;
}

.top-blog ul li h3 {
  margin-bottom: 10px;
  color: #2566BC;
  line-height: 1.3;
}

@media screen and (max-width: 599px) {
  .top-blog ul li h3 {
    margin-bottom: 5px;
  }
}

.top-blog ul li .date {
  margin-bottom: 10px;
  font-size: 12px;
}

@media screen and (max-width: 599px) {
  .top-blog ul li .date {
    margin-bottom: 5px;
  }
}

.top-blog ul li p {
  font-size: 15px;
  line-height: 1.3;
}

.top-blog .btn-more {
  text-align: center;
}

.top-blog .btn-more a {
  padding: 20px 100px;
  border-radius: 40px;
  font-size: 20px;
  letter-spacing: .2em;
  text-align: center;
}

/* トップページ　ABOUT */
.about {
  margin-bottom: 50px;
  padding: 10px 0 50px;
  background: #E7E7E7;
}

.about__inner {
  width: 1200px;
  margin-left: auto;
  margin-right: auto;
}

@media screen and (max-width: 599px) {
  .about__inner {
    width: auto;
    margin-left: 10px;
    margin-right: 10px;
  }
}

.about ul li {
  position: relative;
  float: left;
  width: 372px;
  margin-right: 40px;
}

@media screen and (max-width: 599px) {
  .about ul li {
    float: none;
    width: auto;
    margin-right: 0;
    margin-bottom: 20px;
  }
}

.about ul li:last-child {
  margin-right: 0;
}

@media screen and (max-width: 599px) {
  .about ul li .thumbnail {
    text-align: center;
  }
}

.about ul li .thumbnail img {
  border-radius: 200px;
  border: 14px solid #fff;
}

@media screen and (max-width: 599px) {
  .about ul li .thumbnail img {
    box-sizing: border-box;
    border: 10px solid #fff;
  }
}

.about ul li h3 {
  position: relative;
  top: -50px;
  right: -70px;
  width: 300px;
  padding: 10px;
  border-radius: 4px;
  background: #3BA0FF;
  color: #fff;
  text-align: center;
  font-size: 20px;
  /*-webkit-font-smoothing: antialiased;*/
}

@media screen and (max-width: 599px) {
  .about ul li h3 {
    top: -30px;
    right: 0;
    width: auto;
    font-size: 16px;
    font-size: 4.26667vw;
  }
}

.about ul li p {
  color: #666666;
}

.contact-box {
  width: 1200px;
  margin-left: auto;
  margin-right: auto;
}

@media screen and (max-width: 599px) {
  .contact-box {
    width: auto;
    margin-left: 10px;
    margin-right: 10px;
  }
}

/*--------------------------------------------------------
  固定ページ共通
----------------------------------------------------------*/
/* メインビジュアル */
.page-mv__title {
  position: relative;
}

@media screen and (max-width: 599px) {
  .page-mv__title {
    margin-bottom: 30px;
  }
}

.page-mv__title--noimage {
  width: 1200px;
  margin-left: auto;
  margin-right: auto;
  padding-top: 60px;
  font-size: 40px;
  font-weight: normal;
  text-align: center;
}

@media screen and (max-width: 599px) {
  .page-mv__title--noimage {
    width: auto;
    margin-left: 0;
    margin-right: 0;
  }
}

@media screen and (max-width: 599px) {
  .page-mv__title--noimage {
    font-size: 18px;
    font-size: 4.8vw;
    padding-top: 60px;
  }
}

.page-mv__title__wrapper {
  height: 200px;
  background: url(/theme/bccolumn/img/page/mv_page.jpg) no-repeat;
  background-size: cover;
}

@media screen and (max-width: 599px) {
  .page-mv__title__wrapper {
    height: 160px;
    margin-bottom: 30px;
  }
}

/* サブページ共通 */
.page p {
  margin-bottom: 30px;
}

@media screen and (max-width: 599px) {
  .page p {
    margin-bottom: 20px;
  }
}

.page .section {
  margin-bottom: 60px;
}

@media screen and (max-width: 599px) {
  .page .section {
    margin-bottom: 30px;
  }
}

.page .copy {
  color: #2566BC;
  font-size: 30px;
  font-weight: bold;
}

@media screen and (max-width: 599px) {
  .page .copy {
    font-size: 18px;
    font-size: 4.8vw;
  }
}

@media screen and (max-width: 599px) {
  .sp-ta-center {
    text-align: center;
  }
}

/* サービス（シートクリーニング） */
.banner-contact {
  width: 800px;
  margin: 0 auto 50px;
  border: 4px solid #FF8724;
}

@media screen and (max-width: 599px) {
  .banner-contact {
    width: auto;
  }
}

.banner-contact p {
  margin-bottom: 20px;
}

.banner-contact__title {
  background: #FF8724;
  color: #fff;
  font-size: 28px;
  font-weight: bold;
  text-align: center;
}

@media screen and (max-width: 599px) {
  .banner-contact__title {
    font-size: 24px;
    font-size: 6.4vw;
    padding: 10px 0 20px;
    line-height: 1.2;
  }
}

.banner-contact__title b {
  font-size: 36px;
}

@media screen and (max-width: 599px) {
  .banner-contact__title b {
    display: block;
    font-size: 28px;
    font-size: 7.46667vw;
  }
}

.banner-contact__wrap {
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
  padding: 10px 50px 30px;
}

@media screen and (max-width: 599px) {
  .banner-contact__wrap {
    display: block;
    width: auto;
  }
}

@media screen and (max-width: 599px) {
  .banner-contact__wrap {
    padding: 10px;
  }
}

.banner-contact__tel__no {
  margin-bottom: 0 !important;
  color: #2566BC;
  font-size: 32px;
  font-weight: bold;
  text-align: center;
  line-height: 1.5;
}

@media screen and (max-width: 599px) {
  .banner-contact__tel__no {
    font-size: 26px;
    font-size: 6.93333vw;
  }
}

.banner-contact__tel__no a {
  color: #2566BC;
}

.banner-contact__tel__time {
  margin-bottom: 0 !important;
}

@media screen and (max-width: 599px) {
  .banner-contact__tel__time {
    font-size: 14px;
    font-size: 3.73333vw;
    margin-bottom: 20px !important;
    text-align: center;
  }
}

.banner-contact__contact {
  text-align: center;
}

@media screen and (max-width: 599px) {
  .banner-contact__contact {
    font-size: 20px;
  }
}

.service-trouble p {
  margin-bottom: 10px;
}

.service-trouble__title {
  margin-bottom: 30px !important;
  font-size: 50px;
  font-weight: bold;
  text-align: center;
}

@media screen and (max-width: 599px) {
  .service-trouble__title {
    font-size: 24px;
    font-size: 6.4vw;
    margin-bottom: 15px !important;
  }
}

.service-trouble__list {
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
}

@media screen and (max-width: 599px) {
  .service-trouble__list {
    display: block;
    width: auto;
  }
}

.service-trouble__list__item {
  width: 32%;
}

@media screen and (max-width: 599px) {
  .service-trouble__list__item {
    width: auto;
  }
}

.service-trouble__list__item__title {
  margin-bottom: 10px !important;
  padding: 10px 20px;
  background: #5BB3FF;
  color: #fff;
  font-size: 20px;
  line-height: 1.5;
}

@media screen and (max-width: 599px) {
  .service-trouble__list__item__title {
    font-size: 18px;
    font-size: 4.8vw;
  }
}

.service-feature {
  padding: 30px 100px;
  background: #E3E7ED;
}

@media screen and (max-width: 599px) {
  .service-feature {
    padding: 10px;
  }
}

.service-feature p {
  margin-bottom: 10px;
}

.service-feature__title {
  color: #2566BC;
  font-size: 50px;
  font-weight: bold;
  text-align: center;
}

@media screen and (max-width: 599px) {
  .service-feature__title {
    font-size: 30px;
    font-size: 8vw;
  }
}

.service-feature__list__item strong {
  color: #2566BC;
  font-weight: bold;
}

.page ol > li.service-feature__list__item {
  position: relative;
  padding-left: 50px;
  font-size: 30px;
}

@media screen and (max-width: 599px) {
  .page ol > li.service-feature__list__item {
    font-size: 18px;
    font-size: 4.8vw;
    padding-left: 36px;
  }
}

.page ol > li.service-feature__list__item::before {
  top: 10px;
  left: 0;
  content: "";
  width: 30px;
  height: 30px;
  border: 2px solid #2566BC;
  border-radius: 4px;
}

@media screen and (max-width: 599px) {
  .page ol > li.service-feature__list__item::before {
    top: 6px;
    width: 20px;
    height: 20px;
  }
}

.page ol > li.service-feature__list__item::after {
  position: absolute;
  top: 2px;
  left: 14px;
  content: "";
  display: block;
  width: 12px;
  height: 28px;
  border-right: 6px solid #2566BC;
  border-bottom: 6px solid #2566BC;
  transform: rotate(50deg);
}

@media screen and (max-width: 599px) {
  .page ol > li.service-feature__list__item::after {
    top: 2px;
    left: 10px;
    width: 8px;
    height: 18px;
    border-width: 4px;
  }
}

.service-photo p {
  margin-bottom: 0;
}

.service-photo__title {
  margin-bottom: 30px !important;
  font-size: 50px;
  font-weight: bold;
  text-align: center;
}

@media screen and (max-width: 599px) {
  .service-photo__title {
    font-size: 24px;
    font-size: 6.4vw;
    margin-bottom: 15px !important;
  }
}

.service-photo__list {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.service-photo__list__item {
  width: 32%;
  border: 1px solid #E3E7ED;
  text-align: center;
}

@media screen and (max-width: 599px) {
  .service-photo__list__item {
    width: 48%;
    text-align: left;
  }
}

.page ul {
  margin-left: 0;
}

.page ul > li.service-photo__list__item {
  margin-bottom: 30px;
  padding: 15px;
}

@media screen and (max-width: 599px) {
  .page ul > li.service-photo__list__item {
    padding: 8px;
  }
}

.page ul > li.service-photo__list__item::before {
  display: none;
}

.page ul > li.service-photo__list__item p {
  margin-bottom: 0;
}

@media screen and (max-width: 599px) {
  .service-price {
    border-top: 1px solid #5BB3FF;
  }
}

@media screen and (max-width: 599px) {
  .service-price table th {
    display: none;
  }
}

.main .service-price table td:first-child {
  padding: 20px 20px 20px 300px;
}

@media screen and (max-width: 599px) {
  .main .service-price table td:first-child {
    padding: 20px 10px 0 10px;
  }
}

.main .service-price table td:last-child {
  padding: 20px 300px 20px 20px;
}

@media screen and (max-width: 599px) {
  .main .service-price table td:last-child {
    padding: 0 10px 20px 10px;
    text-align: right;
  }
}

/* 会社案内 */
.rinen {
  text-align: center;
}

@media screen and (max-width: 599px) {
  .rinen {
    text-align: left;
  }
}

.rinen__copy {
  font-family: "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ Ｐ明朝", "MS PMincho", serif;
  font-size: 30px;
}

@media screen and (max-width: 599px) {
  .rinen__copy {
    font-size: 16px;
    font-size: 4.26667vw;
  }
}

.rinen__message {
  font-size: 18px;
}

@media screen and (max-width: 599px) {
  .rinen__message {
    font-size: 14px;
    font-size: 3.73333vw;
  }
}

.ceo {
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
}

@media screen and (max-width: 599px) {
  .ceo {
    display: block;
    width: auto;
  }
}

.ceo__message {
  width: 65%;
  padding: 30px 50px;
  font-family: "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ Ｐ明朝", "MS PMincho", serif;
  font-size: 18px;
  line-height: 2;
}

@media screen and (max-width: 599px) {
  .ceo__message {
    width: auto;
    padding: 0;
    font-size: 14px;
    font-size: 3.73333vw;
    line-height: 1.5;
  }
}

.ceo__photo {
  width: 30%;
}

@media screen and (max-width: 599px) {
  .ceo__photo {
    width: auto;
  }
}

/* ブログ */
.blog--default .tag {
  margin: -20px 0 20px;
}

.blog--default .category {
  display: inline-block;
  margin-bottom: 10px;
}

@media screen and (max-width: 599px) {
  .blog--default .category {
    display: block;
  }
}

.blog--default .category a {
  display: inline-block;
  padding: 2px 15px;
  border: 1px solid #5BB3FF;
  text-decoration: none;
  color: #2566BC;
}

.blog--default .more {
  text-align: right;
}

.blog--index .post {
  position: relative;
  margin-bottom: 80px;
}

@media screen and (max-width: 599px) {
  .blog--index .post {
    margin-bottom: 30px;
  }
}

.blog--index .category {
  position: absolute;
  top: 8px;
  right: 0;
  display: inline-block;
}

@media screen and (max-width: 599px) {
  .blog--index .category {
    position: static;
    display: block;
  }
}

.blog--index .eye-catch {
  float: left;
  margin-right: 20px;
}

@media screen and (max-width: 599px) {
  .blog--index .eye-catch {
    float: none;
    margin-right: 0;
  }
}

@media screen and (max-width: 599px) {
  .blog--index .eye-catch img {
    width: 100%;
  }
}

.blog--single .eye-catch {
  margin-bottom: 30px;
}

@media screen and (max-width: 599px) {
  .blog--single .eye-catch img {
    width: 100%;
  }
}
